<template>
  <v-toolbar
    class="app-toolbar px-2"
    :color="dark ? 'ui-kit-icon-primary' : undefined"
    :class="{ 'app-toolbar_borderless': borderless }"
    :fixed="fixed"
    short
    flat
  >
    <v-icon
      v-if="backTo"
      icon="ui-icon-arrow-back"
      color="ui-kit-icon-primary"
      @click="$emit('click:back')"
    >
      <v-icon />
    </v-icon>

    <v-icon
      v-if="closeTo"
      icon="ui-icon-close-not-a-circle"
      color="ui-kit-icon-primary"
      @click="$emit('click:close')"
    />

    <v-toolbar-title
      v-if="title || $slots.default"
      class="ml-8"
    >
      <slot>
        {{ title }}
      </slot>
    </v-toolbar-title>
    <v-spacer v-if="$slots['append-icon'] || appendIcon" />
    <slot name="append-icon">
      <v-icon
        v-if="appendIcon"
        :color="dark ? 'ui-kit-bg-gray-0' : 'ui-kit-text'"
        @click="emitClickIcon"
      >
        {{ appendIcon }}
      </v-icon>
    </slot>
  </v-toolbar>
</template>

<script lang="ts">

export default defineComponent({
  name: 'AppToolbar',
  props: {
    title: {
      type: String,
      default: ''
    },
    backTo: {
      type: Boolean,
      default: false
    },
    closeTo: {
      type: Boolean,
      default: false
    },
    borderless: { type: Boolean },
    fixed: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click:back', 'click:icon', 'click:close'],
  setup(_, { emit }) {
    function emitClickIcon() {
      emit('click:icon')
    }

    return {
      emitClickIcon
    }
  }
})
</script>

<style scoped lang="scss">
body .app-toolbar {
  border-bottom: 1px solid $t-ui-kit-bg-gray-60;

  &_borderless {
    border-bottom: 0;
  }

  & .v-toolbar-title {
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
