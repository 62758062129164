<template>
  <v-dialog
    v-model="isActive"
    :width="$device.isDesktop ? 304 : '100%'"
    :fullscreen="!$device.isDesktop"
  >
    <v-sheet class="d-flex flex-column">
      <AppToolbar
        v-if="!$device.isDesktop"
        close-to
        borderless
        @click:close="isActive = false"
      />
      <div :class="['pt-6 px-6', {'text-center mb-auto': !$device.isDesktop}]">
        <v-img
          width="150"
          height="150"
          class="mx-auto"
          :src="content.image"
        />
        <p class="text-title">{{ content.title }}</p>
        <p
          v-if="content.text"
          class="mt-4"
          v-html="content.text"
        />
      </div>

      <div class="mt-6 px-2 pb-2 d-flex justify-end">
        <v-btn
          class="mt-auto"
          :variant="buttonVariant"
          :block="!$device.isDesktop"
          @click="isActive = false"
        >
          {{ content.buttonText }}
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import { ContactFormDialogMessageType } from '~/enums'

type Content = {
  image: string
  title: string
  text?: string
  buttonText: string
}

export default defineComponent({
  name: 'ContactFormDialogMessage',
  setup(_, { expose }) {
    const isActive = ref(false)
    const type = ref<ContactFormDialogMessageType>(ContactFormDialogMessageType.Success)
    const $device = useDevice()

    const content = computed<Content>(() => {
      switch (type.value) {
        case ContactFormDialogMessageType.Success:
          return {
            image: '/images/success.svg',
            title: 'Заявка отправлена',
            text: 'Специалист позвонит вам в&nbsp;течение одного рабочего дня',
            buttonText: 'Хорошо'
          }
        case ContactFormDialogMessageType.Error:
          return {
            image: '/images/cancel.svg',
            title: 'Отправить новую заявку можно через сутки',
            buttonText: 'Понятно'
          }
      }
    })

    const buttonVariant = computed(() => {
      return $device.isDesktop ? 'text' : 'elevated'
    })

    function show(messageType: ContactFormDialogMessageType) {
      type.value = messageType
      isActive.value = true
    }

    expose({ show })

    return {
      buttonVariant,
      isActive,
      content
    }
  }
})
</script>

<style scoped lang="scss">
.text-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.15px;
}
</style>
