import { ContactFormRequest } from '~/enums'

type SaveContactPayload = {
  requestType: ContactFormRequest
  mobilePhone: string
  town: string
  clinic: string
}

type Integration = {
  title: string
  logoUrl: string
}

export type StatisticsDto = {
  lpusCount: number
  directAppointmentsCount: number
  integrationsCount: number
  integrations: Integration[]
}

export type SaveContactErrorDto = {
  requestType?: string[],
  mobilePhone?: string[],
  detail?: string
}

export const homeApi = {
  getStatistics() {
    return $apiFetch<StatisticsDto>('/common/')
  },
  saveContact(payload: SaveContactPayload) {
    return $apiFetch<SaveContactErrorDto>('/save_contact/', {
      body: payload,
      method: 'POST'
    })
  }
}
