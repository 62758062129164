<template>
  <v-dialog
    v-model="isActive"
    :width="$device.isDesktop ? 456 : '100%'"
    :fullscreen="!$device.isDesktop"
    :transition="!$device.isDesktop ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-sheet class="contact-form">
      <AppToolbar
        :title="formContent.title"
        close-to
        @click:close="hide"
      />

      <div class="pa-6 d-flex flex-column">
        <p class="mb-6">{{ formContent.text }}</p>

        <v-text-field
          v-model="town"
          class="mb-4"
          label="Город"
          :density="$device.isDesktop ? 'comfortable' : 'default'"
          :rules="[rules.town]"
        />

        <v-text-field
          v-model="clinic"
          class="mb-4"
          label="Клиника"
          :density="$device.isDesktop ? 'comfortable' : 'default'"
          :rules="[rules.clinic]"
        />

        <PhoneInput
          ref="phoneInput"
          v-model="phone"
          class="mb-4"
          label="Номер телефона"
          :density="$device.isDesktop ? 'comfortable' : 'default'"
          @focus="handleFocusPhoneInput"
        />

        <p
          class="text-body-2 text-ui-kit-text-secondary"
          :class="{'order-2 mt-4 text-center': !$device.isDesktop }"
        >
          Нажимая на кнопку «Жду звонка», вы даёте согласие на
          <a
            class="text-underline text-ui-kit-text-secondary"
            :href="privacyPolicyLink"
          >
            обработку персональных данных
          </a>
        </p>

        <v-btn
          class="ml-auto"
          :class="{'mt-4': $device.isDesktop }"
          :block="!$device.isDesktop"
          :disabled="!isValidForm"
          :loading="isPending"
          @click="handleClickSend"
        >
          Жду звонка
        </v-btn>
      </div>
    </v-sheet>
  </v-dialog>
  <v-snackbar
    v-model="snackbar"
    :timeout="4000"
  >
    Произошла ошибка, попробуйте ещё раз
  </v-snackbar>
</template>

<script lang="ts">
import { VTextField } from 'vuetify/components/VTextField'
import { homeApi } from '~/api'
import { ContactFormRequest } from '~/enums'

const PHONE_LENGTH = 11
const TOWN_LENGTH = 50
const CLINIC_LENGTH = 100

const rules = {
  town: (value: string) => value.length <= TOWN_LENGTH || `Должно быть не больше ${TOWN_LENGTH} символов`,
  clinic: (value: string) => value.length <= CLINIC_LENGTH || `Должно быть не больше ${CLINIC_LENGTH} символов`
}

export default defineComponent({
  name: 'ContactFormDialog',
  emits: ['send', 'error', 'error:limit'],
  setup(_, {
    emit,
    expose
  }) {
    const phoneInput = ref<VTextField | null>(null)
    const isActive = ref(false)
    const isPending = ref(false)
    const snackbar = ref(false)
    const phone = ref('')
    const town = ref('')
    const clinic = ref('')
    const formType = ref<ContactFormRequest | null>(null)
    const privacyPolicyLink =
        useRuntimeConfig().public.mfUrl + '/static/dms/pdf/personal_data_processing_policy.pdf'

    const $device = useDevice()

    const isValidForm = computed(() =>
      formType.value !== null &&
        getOnlyNumber(phone.value).length === PHONE_LENGTH &&
        town.value &&
        clinic.value
    )

    const formContent = computed(() => {
      switch (formType.value) {
        case ContactFormRequest.ConnectMedflex:
          return {
            title: 'Подключить МедФлекс',
            text: 'Оставьте контакты — мы перезвоним и расскажем, как подключить МедФлекс. '
          }
        case ContactFormRequest.InstallingWidgets:
          return {
            title: $device.isDesktop ? 'Заявка на установку виджетов' : 'Заявка на установку',
            text: 'Оставьте контакты — мы перезвоним и поможем установить виджеты на ваш сайт'
          }
      }

      return {
        title: '',
        text: ''
      }
    })

    watch(isActive, (val) => {
      if (!val) {
        town.value = ''
        clinic.value = ''
        phone.value = ''
      }
    })

    function show(type: ContactFormRequest) {
      isActive.value = true
      formType.value = type
    }

    function hide() {
      isActive.value = false
    }

    function handleFocusPhoneInput() {
      phoneInput.value?.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }

    async function handleClickSend() {
      if (!isValidForm.value) {
        return
      }

      isPending.value = true

      const { error } = await useAsyncData(() => homeApi.saveContact({
        requestType: formType.value!,
        mobilePhone: getOnlyNumber(phone.value),
        town: town.value,
        clinic: clinic.value
      }))

      isPending.value = false

      if (!error.value) {
        hide()
        emit('send')

        return
      }

      if (error.value?.statusCode === 429) {
        hide()
        emit('error:limit')

        return
      }

      snackbar.value = true
    }

    expose({
      show
    })

    return {
      phoneInput,
      isActive,
      isPending,
      snackbar,
      phone,
      town,
      clinic,
      privacyPolicyLink,
      formContent,
      isValidForm,
      rules,
      hide,
      handleFocusPhoneInput,
      handleClickSend
    }
  }
})
</script>

<style scoped lang="scss">
.contact-form {
  &__header {
    border-bottom: 1px solid $t-ui-kit-bg-gray-60;
  }
}
</style>
